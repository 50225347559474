// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton-container {
  position: relative;
  overflow: hidden;
  width: var(--width, 100%);
  height: var(--height, 56px);
  border-radius: var(--border-radius, 4px);
  background-color:  #eeeded6d
}

.skeleton-container.image {
  background-color: none;
}

.skeleton-container.circle {
  border-radius: 50%;
}

.skeleton-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: -20%;
  width: 140%;
  height: 100%;
  background: linear-gradient(
    to right,
    #f0f0f0, 
    rgba(153, 153, 153, 0.3),
    #f0f0f0 
  );
  background-size: 50px auto;
  background-repeat: repeat-y;
  transform: skew(-10deg);
  animation: waveAnimation 2s linear infinite;
}

@keyframes waveAnimation {
  from {
    background-position: calc(0% - 50px) 0;
  }
  to {
    background-position: 200% 0;
  }
}

.skeleton-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./src/components/localSkeletonLoading/LocalSkeletonLoading.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;EACzB,2BAA2B;EAC3B,wCAAwC;EACxC;AACF;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,UAAU;EACV,WAAW;EACX,YAAY;EACZ;;;;;GAKC;EACD,0BAA0B;EAC1B,2BAA2B;EAC3B,uBAAuB;EACvB,2CAA2C;AAC7C;;AAEA;EACE;IACE,sCAAsC;EACxC;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".skeleton-container {\n  position: relative;\n  overflow: hidden;\n  width: var(--width, 100%);\n  height: var(--height, 56px);\n  border-radius: var(--border-radius, 4px);\n  background-color:  #eeeded6d\n}\n\n.skeleton-container.image {\n  background-color: none;\n}\n\n.skeleton-container.circle {\n  border-radius: 50%;\n}\n\n.skeleton-container::after {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  left: -20%;\n  width: 140%;\n  height: 100%;\n  background: linear-gradient(\n    to right,\n    #f0f0f0, \n    rgba(153, 153, 153, 0.3),\n    #f0f0f0 \n  );\n  background-size: 50px auto;\n  background-repeat: repeat-y;\n  transform: skew(-10deg);\n  animation: waveAnimation 2s linear infinite;\n}\n\n@keyframes waveAnimation {\n  from {\n    background-position: calc(0% - 50px) 0;\n  }\n  to {\n    background-position: 200% 0;\n  }\n}\n\n.skeleton-image {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
