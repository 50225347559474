// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-boundary-container {
  height: 65vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFF;
  box-shadow: 0px 4px 6px #00000029;
  border-radius: 16px;
  margin-top: 20px;
}

.error-boundary-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-boundary-pre {
  padding: 10px;
  text-wrap: wrap;
  background-color: #e3dfdf29;
  font-size: 12px;
  font-family: monospace;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #e3dfdf;
  border-radius: 8px;
  max-height: 200px;
  max-width: 650px;
  overflow: scroll;
}
`, "",{"version":3,"sources":["webpack://./src/components/mfeErrorBoundary/MfeErrorBoundary.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,uBAAuB;EACvB,iCAAiC;EACjC,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,2BAA2B;EAC3B,eAAe;EACf,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".error-boundary-container {\n  height: 65vh;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #FFFF;\n  box-shadow: 0px 4px 6px #00000029;\n  border-radius: 16px;\n  margin-top: 20px;\n}\n\n.error-boundary-content {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.error-boundary-pre {\n  padding: 10px;\n  text-wrap: wrap;\n  background-color: #e3dfdf29;\n  font-size: 12px;\n  font-family: monospace;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  border: 1px solid #e3dfdf;\n  border-radius: 8px;\n  max-height: 200px;\n  max-width: 650px;\n  overflow: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
